import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tap } from 'rxjs/operators';
import { HttpCacheStorage } from '@ngneat/cashew';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor(private snackbar: MatSnackBar, private cacheStorage: HttpCacheStorage) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(event => {

      if (event instanceof HttpResponse) {
        if (req.url.includes('/pre_submit/')) return;
        
        if (['DELETE', 'POST', 'PATCH', 'PUT'].includes(req.method)) {
          this.cacheStorage.delete();
          this.snackbar.open('Successfully executed your request.', 'Okay', {
            duration: 2500
          });
        }
      }
    }));
  }
}
