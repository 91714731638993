import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanLoad {

  constructor(private router: Router, private auth: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.token) {
      if (state.url.includes('login')) this.router.navigate(['/'])
      return true
    } else if (state.url.includes('login')) {
      return true
    } else {
      this.router.navigate(['/login']);
      return false;
    }
    // this.store.pipe(
    //   select(canActivateRoute, { route: state.url, menuItems: this.menu.getMenuitem() }),
    //   tap((canActivate) => {
    //     if (!Swal.isLoading)
    //       if (!canActivate)
    //         this.router.navigateByUrl('404')
    //   })
    // )
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (!this.auth.token) {
      return this.router.parseUrl('login')
    }
    return true
  }
}
