import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

import { AppBlankComponent } from './components/layouts/blank/blank.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ErrorComponent } from './authentication/error/error.component';
import { ErrorInterceptor, HeaderInterceptor, ProgressIndicatorInterceptor, ResponseInterceptor } from './core/services/interceptors';
import { HttpCacheInterceptorModule } from '@ngneat/cashew';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    AppComponent,
    AppBlankComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatIconModule, // for error page
    MatSnackBarModule, // for interceptors
    HttpClientModule,
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
    HttpCacheInterceptorModule.forRoot({
      strategy: 'implicit',
      ttl: 150000
    }),
    NgxPermissionsModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ProgressIndicatorInterceptor, multi: true }
  ]
})
export class AppModule { }
